import React from 'react';

type Props = {
  className?: string;
};
const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="66" height="16" viewBox="0 0 66 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M24.6183 3.57812H20.9923C20.7442 3.57812 20.5332 3.75839 20.4945 4.00334L19.028 13.3012C18.9988 13.4847 19.1409 13.6501 19.327 13.6501H21.0581C21.3062 13.6501 21.5172 13.4698 21.5559 13.2244L21.9514 10.7166C21.9896 10.4711 22.2011 10.2908 22.4487 10.2908H23.5966C25.9851 10.2908 27.3636 9.13502 27.7236 6.84461C27.8858 5.84256 27.7305 5.05523 27.2613 4.50383C26.7459 3.89836 25.8319 3.57812 24.6183 3.57812ZM25.0366 6.97398C24.8383 8.27506 23.8442 8.27506 22.883 8.27506H22.3358L22.7197 5.84521C22.7425 5.69834 22.8697 5.59019 23.0182 5.59019H23.2689C23.9237 5.59019 24.5414 5.59019 24.8606 5.96344C25.0509 6.18612 25.1092 6.51695 25.0366 6.97398Z"
      fill="#253B80"
    />
    <path
      d="M35.4567 6.93185H33.7204C33.5724 6.93185 33.4447 7.04001 33.4219 7.18687L33.345 7.67252L33.2236 7.4965C32.8477 6.95094 32.0094 6.76855 31.1728 6.76855C29.2541 6.76855 27.6153 8.2218 27.2961 10.2604C27.1301 11.2773 27.3661 12.2496 27.9429 12.9277C28.472 13.5512 29.2291 13.811 30.1299 13.811C31.676 13.811 32.5333 12.8169 32.5333 12.8169L32.4559 13.2994C32.4267 13.4839 32.5688 13.6493 32.7538 13.6493H34.3179C34.5665 13.6493 34.7765 13.4691 34.8157 13.2236L35.7542 7.28072C35.7839 7.0978 35.6423 6.93185 35.4567 6.93185ZM33.0364 10.3113C32.8689 11.3032 32.0816 11.9692 31.0774 11.9692C30.5732 11.9692 30.1702 11.8075 29.9115 11.501C29.6549 11.1967 29.5573 10.7635 29.639 10.281C29.7954 9.29755 30.596 8.6099 31.5848 8.6099C32.0778 8.6099 32.4787 8.77372 32.7427 9.08282C33.0073 9.3951 33.1122 9.83092 33.0364 10.3113Z"
      fill="#253B80"
    />
    <path
      d="M44.7052 6.93213H42.9603C42.7938 6.93213 42.6374 7.01484 42.5431 7.15322L40.1365 10.6981L39.1165 7.2916C39.0523 7.07846 38.8556 6.93213 38.6329 6.93213H36.9183C36.7099 6.93213 36.5652 7.13572 36.6315 7.33189L38.5534 12.972L36.7465 15.5228C36.6044 15.7237 36.7476 15.9999 36.9931 15.9999H38.7358C38.9012 15.9999 39.056 15.9193 39.1499 15.7836L44.9533 7.40665C45.0922 7.20624 44.9496 6.93213 44.7052 6.93213Z"
      fill="#253B80"
    />
    <path
      d="M50.4828 3.57812H46.8563C46.6087 3.57812 46.3977 3.75839 46.359 4.00334L44.8925 13.3012C44.8633 13.4847 45.0054 13.6501 45.1904 13.6501H47.0514C47.2242 13.6501 47.3722 13.5239 47.3992 13.3521L47.8154 10.7166C47.8536 10.4711 48.0651 10.2908 48.3127 10.2908H49.46C51.8491 10.2908 53.227 9.13502 53.5876 6.84461C53.7503 5.84256 53.5939 5.05523 53.1247 4.50383C52.6099 3.89836 51.6964 3.57812 50.4828 3.57812ZM50.9011 6.97398C50.7033 8.27506 49.7092 8.27506 48.7475 8.27506H48.2009L48.5852 5.84521C48.608 5.69834 48.7342 5.59019 48.8832 5.59019H49.134C49.7882 5.59019 50.4064 5.59019 50.7256 5.96344C50.9159 6.18612 50.9737 6.51695 50.9011 6.97398Z"
      fill="#179BD7"
    />
    <path
      d="M61.3204 6.93185H59.5851C59.4361 6.93185 59.3094 7.04001 59.2872 7.18687L59.2103 7.67252L59.0883 7.4965C58.7124 6.95094 57.8747 6.76855 57.0381 6.76855C55.1194 6.76855 53.4811 8.2218 53.1619 10.2604C52.9965 11.2773 53.2314 12.2496 53.8082 12.9277C54.3384 13.5512 55.0944 13.811 55.9952 13.811C57.5413 13.811 58.3986 12.8169 58.3986 12.8169L58.3212 13.2994C58.292 13.4839 58.4341 13.6493 58.6202 13.6493H60.1837C60.4313 13.6493 60.6423 13.4691 60.681 13.2236L61.62 7.28072C61.6486 7.0978 61.5065 6.93185 61.3204 6.93185ZM58.9001 10.3113C58.7336 11.3032 57.9453 11.9692 56.9411 11.9692C56.4379 11.9692 56.0339 11.8075 55.7752 11.501C55.5186 11.1967 55.4221 10.7635 55.5027 10.281C55.6601 9.29755 56.4597 8.6099 57.4485 8.6099C57.9415 8.6099 58.3424 8.77372 58.6064 9.08282C58.872 9.3951 58.977 9.83092 58.9001 10.3113Z"
      fill="#179BD7"
    />
    <path
      d="M63.3666 3.8327L61.8783 13.3008C61.8492 13.4842 61.9913 13.6497 62.1763 13.6497H63.6725C63.9211 13.6497 64.1321 13.4694 64.1703 13.2239L65.6379 3.92654C65.667 3.7431 65.5249 3.57715 65.3399 3.57715H63.6645C63.5166 3.57768 63.3894 3.68584 63.3666 3.8327Z"
      fill="#179BD7"
    />
    <path
      d="M3.97023 15.4571L4.24752 13.6959L3.62985 13.6815H0.68042L2.73013 0.685059C2.73649 0.645825 2.75716 0.609242 2.78739 0.583263C2.81761 0.557284 2.85631 0.542969 2.8966 0.542969H7.86976C9.52077 0.542969 10.6601 0.88653 11.255 1.56464C11.5339 1.88275 11.7115 2.21518 11.7974 2.58101C11.8875 2.96487 11.8891 3.42348 11.8011 3.98283L11.7947 4.02365V4.38206L12.0736 4.54005C12.3085 4.66465 12.4951 4.80727 12.6383 4.97056C12.8769 5.24255 13.0311 5.58823 13.0964 5.99807C13.1637 6.41957 13.1414 6.92112 13.0311 7.48896C12.9039 8.14215 12.6982 8.71104 12.4204 9.17654C12.1648 9.60546 11.8393 9.96122 11.4528 10.2369C11.0838 10.4988 10.6453 10.6977 10.1496 10.8249C9.66922 10.95 9.12154 11.0131 8.52083 11.0131H8.1338C7.85704 11.0131 7.58823 11.1128 7.37722 11.2915C7.16567 11.4738 7.0257 11.723 6.98276 11.9956L6.9536 12.1541L6.46371 15.2583L6.44144 15.3723C6.43561 15.4084 6.42553 15.4264 6.41069 15.4386C6.39743 15.4497 6.37835 15.4571 6.35979 15.4571H3.97023Z"
      fill="#253B80"
    />
    <path
      d="M12.3367 4.06494C12.3219 4.15984 12.3049 4.25687 12.2858 4.35654C11.63 7.72377 9.38624 8.887 6.52058 8.887H5.0615C4.71105 8.887 4.41573 9.14149 4.36112 9.48717L3.61409 14.2249L3.40254 15.5679C3.36702 15.7948 3.54198 15.9995 3.77102 15.9995H6.35887C6.66532 15.9995 6.92564 15.7768 6.97389 15.4746L6.99934 15.3431L7.48658 12.251L7.51786 12.0814C7.56558 11.7781 7.82643 11.5554 8.13288 11.5554H8.51991C11.0272 11.5554 12.9899 10.5375 13.5636 7.59175C13.8032 6.36118 13.6792 5.33368 13.0451 4.61103C12.8531 4.39313 12.6151 4.21233 12.3367 4.06494Z"
      fill="#179BD7"
    />
    <path
      d="M11.6509 3.79156C11.5507 3.7624 11.4473 3.73589 11.3412 3.71203C11.2347 3.6887 11.1255 3.66802 11.0131 3.65C10.6197 3.58637 10.1886 3.55615 9.72683 3.55615H5.82889C5.73292 3.55615 5.64173 3.57789 5.56008 3.61712C5.38035 3.70354 5.24674 3.87373 5.2144 4.0821L4.38519 9.33413L4.36133 9.48736C4.41594 9.14168 4.71125 8.88719 5.06171 8.88719H6.52078C9.38645 8.88719 11.6302 7.72343 12.286 4.35674C12.3057 4.25706 12.3221 4.16004 12.3369 4.06513C12.171 3.97712 11.9913 3.90183 11.7977 3.83768C11.75 3.82178 11.7007 3.8064 11.6509 3.79156Z"
      fill="#222D65"
    />
    <path
      d="M5.21514 4.08191C5.24748 3.87355 5.38108 3.70336 5.56082 3.61747C5.643 3.57824 5.73366 3.5565 5.82962 3.5565H9.72756C10.1894 3.5565 10.6204 3.58672 11.0138 3.65034C11.1262 3.66837 11.2354 3.68905 11.342 3.71237C11.448 3.73623 11.5514 3.76274 11.6516 3.7919C11.7015 3.80675 11.7508 3.82212 11.799 3.8375C11.9925 3.90165 12.1723 3.97747 12.3382 4.06495C12.5333 2.8206 12.3366 1.97336 11.6638 1.20618C10.9221 0.361588 9.58335 0 7.87032 0H2.89716C2.54723 0 2.24874 0.25449 2.19466 0.600703L0.123216 13.7308C0.0823914 13.9906 0.282802 14.2249 0.544715 14.2249H3.61503L4.38592 9.33395L5.21514 4.08191Z"
      fill="#253B80"
    />
  </svg>
);

export default Icon;
