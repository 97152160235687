import React from 'react';

type Props = {
  className?: string;
};
const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_2575_10508)">
      <path
        d="M1.83254 0.21875H29.8774C30.7625 0.21875 31.48 0.93511 31.48 1.81875V18.6187C31.48 19.5024 30.7625 20.2188 29.8774 20.2188H1.83254C0.947489 20.2188 0.22998 19.5024 0.22998 18.6187V1.81875C0.22998 0.93511 0.947489 0.21875 1.83254 0.21875Z"
        fill="#0E4595"
      />
      <path
        d="M11.9767 14.1682L13.3133 6.33781H15.4511L14.1136 14.1682H11.9767ZM21.8369 6.50662C21.4134 6.34797 20.7497 6.17773 19.921 6.17773C17.8086 6.17773 16.3206 7.23974 16.308 8.76186C16.296 9.88706 17.3702 10.5147 18.181 10.8893C19.0132 11.2731 19.2929 11.5179 19.289 11.8606C19.2837 12.3855 18.6245 12.6253 18.0101 12.6253C17.1544 12.6253 16.6998 12.5066 15.9978 12.2142L15.7223 12.0898L15.4222 13.8428C15.9216 14.0613 16.8449 14.2507 17.8036 14.2605C20.0508 14.2605 21.5095 13.2106 21.5262 11.5852C21.5341 10.6944 20.9646 10.0165 19.7312 9.45766C18.9841 9.09546 18.5264 8.8537 18.5313 8.48694C18.5313 8.16146 18.9186 7.81338 19.7555 7.81338C20.4546 7.80258 20.9611 7.95478 21.3556 8.11338L21.5472 8.20378L21.8369 6.50662ZM27.3381 6.33769H25.6862C25.1744 6.33769 24.7915 6.47718 24.5667 6.98706L21.3919 14.1631H23.6367C23.6367 14.1631 24.0037 13.1982 24.0867 12.9864C24.3321 12.9864 26.5128 12.9897 26.8246 12.9897C26.8886 13.2638 27.0847 14.1631 27.0847 14.1631H29.0684L27.3381 6.33745V6.33769ZM24.7172 11.3941C24.894 10.9429 25.5689 9.20518 25.5689 9.20518C25.5563 9.22606 25.7444 8.75182 25.8524 8.45782L25.9968 9.13298C25.9968 9.13298 26.4062 11.0021 26.4917 11.3941H24.7172ZM10.1619 6.33769L8.06895 11.6777L7.84591 10.5925C7.45629 9.34158 6.24235 7.9863 4.88525 7.30778L6.79896 14.1558L9.06082 14.1532L12.4264 6.33761H10.1619"
        fill="white"
      />
      <path
        d="M6.11629 6.33707H2.66913L2.64185 6.49999C5.32366 7.14815 7.09818 8.71451 7.83496 10.5964L7.08536 6.99799C6.95595 6.50219 6.58067 6.35423 6.11637 6.33691"
        fill="#F2AE14"
      />
    </g>
    <defs>
      <clipPath id="clip0_2575_10508">
        <rect width="31.25" height="20" fill="white" transform="translate(0.22998 0.21875)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
