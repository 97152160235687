import React from 'react';

type Props = {
  className?: string;
};
const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M7.27008 2.0628C6.81337 2.60324 6.08264 3.0295 5.35191 2.9686C5.26057 2.23787 5.61832 1.46147 6.03697 0.981922C6.49368 0.426261 7.29292 0.0304472 7.93992 0C8.01604 0.76118 7.71918 1.50714 7.27008 2.0628ZM7.93231 3.11323C6.87427 3.05233 5.96846 3.71456 5.46608 3.71456C4.95609 3.71456 4.1873 3.14367 3.35 3.1589C2.26152 3.17412 1.24915 3.79068 0.693487 4.7726C-0.448283 6.73644 0.396627 9.64415 1.50034 11.2426C2.04078 12.0343 2.68778 12.902 3.5403 12.8716C4.34715 12.8411 4.66685 12.3463 5.64116 12.3463C6.62308 12.3463 6.90471 12.8716 7.75724 12.8563C8.6402 12.8411 9.19587 12.0647 9.7363 11.2731C10.3529 10.3749 10.604 9.49952 10.6193 9.45385C10.604 9.43863 8.91423 8.79163 8.89901 6.84301C8.88378 5.21408 10.2311 4.43768 10.292 4.39201C9.53079 3.26546 8.34334 3.14367 7.93231 3.11323ZM14.0446 0.905804V12.7726H15.8866V8.71551H18.4366C20.7658 8.71551 22.4023 7.11703 22.4023 4.80304C22.4023 2.48906 20.7962 0.905804 18.4975 0.905804H14.0446ZM15.8866 2.45861H18.0103C19.6088 2.45861 20.5222 3.31113 20.5222 4.81066C20.5222 6.31018 19.6088 7.17031 18.0027 7.17031H15.8866V2.45861ZM25.7668 12.8639C26.9237 12.8639 27.997 12.2778 28.4842 11.3492H28.5222V12.7726H30.2273V6.86584C30.2273 5.15319 28.8571 4.04948 26.7487 4.04948C24.7924 4.04948 23.3462 5.16841 23.2929 6.70599H24.9523C25.0893 5.97526 25.7668 5.49572 26.6954 5.49572C27.8219 5.49572 28.4537 6.02093 28.4537 6.98763V7.64225L26.155 7.77926C24.016 7.90866 22.859 8.78401 22.859 10.3064C22.859 11.844 24.0541 12.8639 25.7668 12.8639ZM26.2615 11.4558C25.2796 11.4558 24.6554 10.9838 24.6554 10.2607C24.6554 9.51475 25.2568 9.08088 26.4061 9.01237L28.4537 8.88297V9.55281C28.4537 10.6641 27.5099 11.4558 26.2615 11.4558ZM32.5032 16C34.2996 16 35.1445 15.3149 35.8828 13.2369L39.1178 4.16365H37.2453L35.076 11.1741H35.0379L32.8686 4.16365H30.9428L34.0636 12.803L33.8962 13.3283C33.6145 14.2188 33.1578 14.5614 32.3433 14.5614C32.1987 14.5614 31.9171 14.5461 31.8029 14.5309V15.9543C31.9095 15.9848 32.3662 16 32.5032 16Z"
      fill="#333333"
    />
  </svg>
);

export default Icon;
